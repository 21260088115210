<div class="authenticate col-xl-10 col-12">
  <h1>Server web authentication</h1>
  <div class="loading" *ngIf="(status$ | async) == 'loading'">
    <div class="spinner-border"></div>
  </div>
  <p class="mt-3" *ngIf="showTimeout$ | async">
    If you haven't been authenticated after 5-10 seconds, disable your adblock
    and try again
  </p>
  <div class="alert alert-danger" *ngIf="(status$ | async) == 'failed'">
    <h2>Authentication failed!</h2>
    <p>
      <span>{{ statusMessage$ | async }}</span>
    </p>
    <p>
      Check our FAQ or contact your server's support staff if you are
      experiencing difficulties
    </p>
  </div>
  <div class="alert alert-success" *ngIf="(status$ | async) == 'success'">
    <h2>Authentication success!</h2>
    <p>
      <span>{{ statusMessage$ | async }}</span>
      <br />
      You can now close your browser
    </p>
  </div>

  <div class="mt-5">
    <h3>FAQ</h3>
    <hr />
    <p>
      <b>Why am I on this page?</b>
    </p>
    <p>
      When you join this server for the first time, you must be authenticated
      through our website. We just run some basic checks to make sure you're
      running a legitimate copy of the game and you're not Battleye banned. This
      is mostly to stop cheaters from abusing the Steam API.
    </p>
    <hr />
    <p>
      <b>Why did my authentication fail?</b>
    </p>
    <p>
      There are many reasons to this. Your authentication session might be
      expired, relogging would fix this. Some Adblockers could also block
      scripts from running on this page, try disabling your adblock.
      <br />
      If you are getting "No authentication request has been sent" error, make
      sure your browser isn't using a VPN/proxy and try disabling your IPv6 in
      Windows. Instructions on disabling your IPv6 can be found
      <a href="https://proprivacy.com/vpn/guides/disable-ipv6" target="_blank">
        here.
      </a>
    </p>
    <hr />
    <p>
      <b>Who owns this website?</b>
    </p>
    <p>
      This website is owned and hosted by Fini (Discord: Fini#1337) for the Fini
      Anti-Hack and Admin Tools. You can find more info on
      <a href="https://site.fini.dev/" target="_blank">here.</a>
      <br />
      Our privacy policy can be found
      <a href="https://site.fini.dev/privacy-policy/" target="_blank">
        here.
      </a>
    </p>
  </div>
</div>
<div class="privacy-reminder">
  <p>
    This page may use cookies and other tracking devices to serve it's techical
    purpose. Data stored on your device will not be shared with third parties
    without your consent.
    <a href="https://site.fini.dev/privacy-policy/" target="_blank">
      More info
    </a>
  </p>
</div>
